import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import store from '../store/index'

Vue.use(VueRouter)
Vue.use(Vuex)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/static/Home.vue'),
    meta: {
      title: '首页-TextualESG'
    }
  },
  {
    path: '/about',
    name: 'about',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/static/About.vue'),
    meta: {
      title: '关于-TextualESG'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',

    component: () => import('../views/static/Privacy.vue'),
    meta: {
      title: '条款-TextualESG'
    }
  },
  {
    path: '/document',
    name: 'document',
    component: () => import('../views/static/Document.vue'),
    meta: {
      title: '文档-TextualESG'
    }
  },
  {
    path: '/esgindex',
    name: 'esgindex',

    component: () => import('../views/esgindex/ESGIndex.vue'),
    meta: {
      title: 'ESG指数-TextualESG'
    }
  },
  {
    path: '/invest',
    name: 'invest',
    component: () => import('../views/combination/Invest.vue'),
    meta: {
      title: 'ESG投资-TextualESG',
      requireAuth: true
    }
  },
  {
    path: '/rank/company',
    name: 'company',
    component: () => import('../views/company/Company.vue'),
    meta: {
      activeMenu: '/rank/indexbrowser',
      requireAuth: true,
      title: '公司详情-TextualESG'
    }
  },
  {
    path: '/rank/indexbrowser',
    name: 'indexbrowser',
    component: () => import('../views/company/IndexBrowser.vue'),
    meta: {
      activeMenu: '/rank/indexbrowser',
      requireAuth: true,
      title: 'ESG指标-TextualESG'
    }
  },
  {
    path: '/combination',
    name: 'combination',
    component: () => import('../views/combination/Combination.vue'),
    meta: {
      requireAuth: true,
      title: '自选组合-TextualESG'
    }
  },
  {
    path: '/esgtext',
    name: 'esgtext',
    meta: {
      requireAuth: true,
      title: '文本详情-TextualESG'
    },
    component: () => import('../views/esgtext/ESGText.vue')
  },
  {
    path: '/esgtexthome',
    name: 'esgtexthome',
    meta: {
      title: '文本详情-TextualESG'
    },
    component: () => import('../views/esgtext/HomeESGText.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/public/Register.vue'),
    meta: {
      title: '注册-TextualESG'
    }
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('../views/public/ResetPassword.vue'),
    meta: {
      title: '重置密码-TextualESG'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/public/Login.vue'),
    meta: {
      title: '登录-TextualESG'
    }
  },
  {
    path: '*',    // 此处需特别注意至于最底部
    name: '404',
    component: () => import('../views/static/404.vue'),
    meta: {
      title: '404-TextualESG'
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/User.vue'),
    meta: {
      title: '用户信息-TextualESG'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.requireAuth) {
    if (store.state.isLogin) {
      console.log('1')
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})
