import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const isLogin = !!localStorage.getItem('store')
let userID = -1
let name = ''
let verifyID = ''
let token = ''
const lastCheckDate = ''
let date = ''
if (isLogin) {
  const str = JSON.parse(localStorage.getItem('store'))
  userID = str.userID
  name = str.name
  verifyID = str.verifyID
  token = str.token
  date = str.date
}
// localStorage.clear()
export default new Vuex.Store({
  state: {
    // isLogin: isLogin,
    isLogin: isLogin,
    userID: userID,
    name: name,
    verifyID: verifyID,
    token: token,
    lastCheckDate: lastCheckDate,
    date: date
  },
  mutations: {
    set (state, payload) {
      state.isLogin = payload.Login
    },
    replace (state, payload) {
      state.isLogin = payload.isLogin
    },
    setID (state, payLoad) {
      state.userID = payLoad.userID
    },
    setName (state, payLoad) {
      state.name = payLoad.name
      console.log(state.name)
    },
    setVerify (state, payLoad) {
      state.verifyID = payLoad.verifyID
    },
    setToken (state, payLoad) {
      state.token = payLoad.token
    },
    setLastCheckDate (state, payLoad) {
      state.lastCheckDate = payLoad.lastCheckDate
    },
    setDate(state, payload){
      state.date = payload.date
    }
  },
  actions: {},
  modules: {}
})
