import "./utils/console.js"
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import ElementUI from 'element-ui'
import store from './store/index.js'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueLazyload from 'vue-lazyload'


library.add(fas)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://citi-1308543285.cos.ap-shanghai.myqcloud.com/404w.gif',
  loading: 'https://citi-1308543285.cos.ap-shanghai.myqcloud.com/loading-buffering.gif',
  attempt: 1
})

// import { Menu,Button, Select, Radio, Container, Aside, Header, Main } from 'element-ui';
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.config.silent = true

Vue.use(ElementUI)
Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App),
  methods: {},
  data: function () {
    return {
      isLogin: false
    }
  }
}).$mount('#app')
