<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>

export default {
  data() {
    return {
      radio: '2',
      isRouterAlive: true
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  watch: {
    $route(to, from) {
      console.log(to.path)
    }
  },
  created() {
    // localStorage.clear()
    if (localStorage.getItem('store')) {
      console.log(localStorage.getItem('store'))
      console.log('has token')
      this.$store.commit('replace', JSON.parse(localStorage.getItem('store')))
    }
    console.log(JSON.parse(localStorage.getItem('store')))
  },
  methods: {
    getUserInfo() {
      this.userInfo = {
        nick: 'yama',
        uid: '10000'
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  mounted() {
    this.getUserInfo()
  }
}
</script>

<style lang="scss">
@import './css/globalCSS.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
  min-width: 120px !important;
}
</style>
